import { useState, useContext, useRef, useEffect } from "react";
import logo_blue from "../../assets/images/blue_logo.png";
import spin from "../../assets/images/spinner.svg";
import { Form, message } from "antd";

import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router";

const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export default function Verify() {
  const { signinUser } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [isSpinning, setSpinning] = useState(false);
  const [isResending, setResending] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [otp, setOtp] = useState<string[]>(Array(6).fill(""));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (index: number, value: string) => {
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < 5) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (index: number, event: React.KeyboardEvent) => {
    if (event.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (event: React.ClipboardEvent) => {
    const pasteData = event.clipboardData.getData("text").slice(0, 6);
    if (/^\d+$/.test(pasteData)) {
      const newOtp = pasteData
        .split("")
        .concat(Array(6 - pasteData.length).fill(""));
      setOtp(newOtp);
      newOtp.forEach((digit, index) => {
        if (digit && inputRefs.current[index]) {
          inputRefs.current[index]?.focus();
        }
      });
    }
    event.preventDefault();
  };

  const formatOTP = (arr: any) => {
    return Number(arr.join(""));
  };

  const handleVerifyOTP = async () => {
    try {
      setSpinning(true);
      const data = { otp: formatOTP(otp), email };
      const uri = `v1/users/otp/verify`;

      const res = await fetch(`${REACT_APP_BASE_API_URL}/${uri}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const response = await res.json();
      if (response?.success) {
        const credentials = {
          user: response?.user,
          token: response?.token,
        };
        signinUser(credentials);
      } else {
        message.error(response?.error);
      }

      setSpinning(false);
    } catch (error) {
      message.error("Network Error");
      setSpinning(false);
    }
  };

  const handleRequestOTP = async () => {
    try {
      setOtp(Array(6).fill(""));
      setResending(true);
      const data = { email };
      const uri = `v1/users/otp/request`;

      const res = await fetch(`${REACT_APP_BASE_API_URL}/${uri}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const response = await res.json();
      if (response?.success) {
        navigate(`/verify?email=${email}`);
      } else {
        message.error(response?.error);
      }

      setResending(false);
    } catch (error) {
      message.error("Network Error");
      setResending(false);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const emailParam = queryParams.get("email");
    if (emailParam) {
      setEmail(emailParam);
    }
  }, []);

  return (
    <div className="flex justify-center bg-gray-100 min-h-screen">
      <div className="max-w-2xl w-full pt-24 sm:pt-36 px-5">
        <div className="flex flex-row justify-center">
          <div className="px-4 py-8 w-full sm:w-3/5 rounded-lg bg-white">
            <div className="text-center mt-4">
              <img className="mx-auto w-44 " src={logo_blue} alt="" />
              <p className="mt-4 text-gray-500 text-xxs">
                Enter OTP code sent to your email
              </p>
            </div>
            <div className="mb-4">
              <Form
                layout="vertical"
                id="verifyOTP"
                form={form}
                name="normal_login"
                className="login-form mt-8"
                onFinish={handleVerifyOTP}
              >
                {/* <div>
                    <Form.Item
                      name="email"
                      label={"Email"}
                      rules={[
                        {
                          required: true,
                          message: "Please enter email",
                        },
                      ]}
                    >
                      <Input type="text" placeholder="Enter your email" onChange={(e: any) => setEmail(e.target.value)} />
                    </Form.Item>
                </div> */}

                <div className="flex flex-col items-center">
                  <div className="flex space-x-2">
                    {otp.map((value, index) => (
                      <input
                        key={index}
                        type="text"
                        value={value}
                        maxLength={1}
                        className="w-8 h-8 text-center border rounded-lg border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        onChange={(e) => handleChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(index, e)}
                        onPaste={index === 0 ? handlePaste : undefined}
                        ref={(el) => (inputRefs.current[index] = el)}
                      />
                    ))}
                  </div>
                  <p className="mt-4 text-sm text-gray-500">
                    Didn’t get the OTP code?{" "}
                    <button
                      type="button"
                      onClick={() => handleRequestOTP()}
                      className="text-blue-500 hover:underline"
                    >
                      {isResending ? "Resending..." : "Resend Code"}
                    </button>
                  </p>
                </div>

                <div className="mt-8 mb-4 flex justify-center">
                  <button
                    onClick={(e: any) => handleVerifyOTP}
                    className="group relative w-1/2 flex justify-center py-3  px-4
                border border-transparent text-sm leading-5 font-medium
                rounded-md text-white bg-karimjee hover:bg-karimjee-dark
                focus:outline-none focus:border-karimjee
                focus:shadow-outline-indigo active:bg-karimjee transition
                duration-150 ease-in-out"
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      <svg
                        className="h-5 w-5 text-white group-hover:text-whitetransition ease-in-out duration-150"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                        />
                      </svg>
                    </span>
                    {isSpinning ? (
                      <span>
                        <img src={spin} alt="spin" height="20px" width="20px" />
                      </span>
                    ) : (
                      <span className="text-white">Verify Email</span>
                    )}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useState, useEffect, useContext } from "react";
import logo_blue from "../../assets/images/blue_logo.png";
import spin from "../../assets/images/spinner.svg";
import { Form, Input, message } from "antd";

import { useNavigate } from "react-router";
import { AuthContext } from "../../contexts/AuthContext";

const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export default function Signin() {
  const { token } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [isSpinning, setSpinning] = useState(false);
  const [form] = Form.useForm();

  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [navigate]);

  const handleSignin = async () => {
    try {
      setSpinning(true);
      const data = { email };
      const uri = `v1/users/otp/request`;

      const res = await fetch(`${REACT_APP_BASE_API_URL}/${uri}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const response = await res.json();
      if (response?.success) {
        navigate(`/verify?email=${email}`);
      } else {
        message.error(response?.error);
      }

      setSpinning(false);
    } catch (error) {
      message.error("Network Error");
      setSpinning(false);
    }
  };

  return (
    <div className="flex justify-center bg-gray-100 min-h-screen">
      <div className="max-w-2xl w-full pt-24 sm:pt-36 px-5">
        <div className="flex flex-row justify-center">
          <div className="px-4 py-8 w-full sm:w-3/5 rounded-lg bg-white">
            <div className="text-center mt-4">
              <img className="mx-auto w-44 " src={logo_blue} alt="" />
              <p className="mt-4 text-gray-500 text-xxs">
                Sign in into your account to continue
              </p>
            </div>
            <div className="mb-4">
              <Form
                layout="vertical"
                id="createSite"
                form={form}
                name="normal_login"
                className="login-form mt-8"
                onFinish={handleSignin}
              >
                <div>
                  <Form.Item
                    name="email"
                    label={"Email"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter email",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Enter your email"
                      onChange={(e: any) => setEmail(e.target.value)}
                      style={{ height: "45px", fontSize: "16px" }}
                    />
                  </Form.Item>
                </div>

                <div className="mt-8 mb-4 flex justify-center">
                  <button
                    onClick={(e: any) => handleSignin}
                    className="group relative w-1/2 flex justify-center py-3 px-4
                border border-transparent text-sm leading-5 font-medium
                rounded-md text-white bg-karimjee hover:bg-karimjee-dark
                focus:outline-none focus:border-karimjee
                focus:shadow-outline-indigo active:bg-karimjee transition
                duration-150 ease-in-out"
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      <svg
                        className="h-5 w-5 text-white group-hover:text-whitetransition ease-in-out duration-150"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                        />
                      </svg>
                    </span>
                    {isSpinning ? (
                      <span>
                        <img src={spin} alt="spin" height="20px" width="20px" />
                      </span>
                    ) : (
                      <span className="text-white">Sign in</span>
                    )}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

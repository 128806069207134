import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";

const handleFetchDistributions = async () => {
  const uri = `v1/distributions`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateDistribution = async (data: any) => {
  const uri = `v1/distributions`;
  const response = postData({ data, uri });
  return response;
};

const handleExpandDistribution = async ({id, data}: any) => {
  const uri = `v1/distributions/${id}`;
  const response = patchData({ data, uri });
  return response;
};


const handleUpdateRemarks = async ({id, data}: any) => {
  const uri = `v1/distributions/${id}/remarks`;
  const response = patchData({ data, uri });
  return response;
};

const handleUpdateFeedback = async ({id, data}: any) => {
  const uri = `v1/distributions/${id}/feedback`;
  const response = patchData({ data, uri });
  return response;
};

const handleDeleteDistribution = async ({id}: any) => {
  const uri = `v1/distributions/${id}`;
  const response = deleteData({ uri });
  return response;
};

const handleCreateSite = async ({id, data}: any) => {
  const uri = `v1/distributions/${id}/sites`;
  const response = postData({ data, uri });
  return response;
};

const handleDeleteSite = async ({id, siteId, data}: any) => {
  const uri = `v1/distributions/${id}/sites/${siteId}`;
  const response = deleteData({ data, uri });
  return response;
};


export {
  handleCreateDistribution,
  handleFetchDistributions,
  handleDeleteDistribution,
  handleExpandDistribution,
  handleUpdateFeedback,
  handleUpdateRemarks,
  handleCreateSite,
  handleDeleteSite,
};

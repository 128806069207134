import { Button, Modal, Form, Row, Col, message, Checkbox } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleUpdateRemarks } from "../../../api/distributions";
import { useContext, useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import { AuthContext } from "../../../contexts/AuthContext";

export default function UpdateRemarks({
  isVisible,
  setVisible,
  distribution,
}: any) {
  const { user } = useContext(AuthContext);
  const [isFeedbacked, setFeedbacked] = useState(false);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isPending } = useMutation({
    mutationFn: handleUpdateRemarks,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["distributions"] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const data = {
      ...values,
      isFeedbacked,
      remarksBy: user?.email,
    };
    await mutate({ id: distribution?._id, data });
  };

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({
      remarks: distribution?.remarks,})
  }, [distribution]);

  return (
    <Modal
      open={isVisible}
      title={`${distribution?.isCommented ? "Update" : "Add"} Remarks : ${distribution?.name}`}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="createRemarks"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="createRemarks"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="remarks"
              label={"Remarks"}
              rules={[
                {
                  required: false,
                  message: "Please enter bandwidth",
                },
              ]}
            >
              <TextArea rows={4} placeholder="Remarks" />
            </Form.Item>
          </Col>
        </Row>

        <div className="my-2">
            <Checkbox checked={isFeedbacked} onChange={() => setFeedbacked(!isFeedbacked)}>
              Require Feedback ( via email notification)
            </Checkbox>
          </div>
      </Form>
    </Modal>
  );
}

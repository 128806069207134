import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";

const handleFetchLocations = async () => {
  const uri = `v1/locations`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateLocation = async (data: any) => {
  const uri = `v1/locations`;
  const response = postData({ data, uri });
  return response;
};

const handleExpandLocation = async ({id, data}: any) => {
  const uri = `v1/locations/${id}`;
  const response = patchData({ data, uri });
  return response;
};

const handleDeleteLocation = async ({id}: any) => {
  const uri = `v1/locations/${id}`;
  const response = deleteData({ uri });
  return response;
};

const handleCreateSite = async ({id, data}: any) => {
  const uri = `v1/locations/${id}/sites`;
  const response = postData({ data, uri });
  return response;
};

const handleDeleteSite = async ({id, siteId, data}: any) => {
  const uri = `v1/locations/${id}/sites/${siteId}`;
  const response = deleteData({ data, uri });
  return response;
};


export {
  handleCreateLocation,
  handleFetchLocations,
  handleDeleteLocation,
  handleExpandLocation,
  handleCreateSite,
  handleDeleteSite,
};

import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

/*
|---------------------------------------------------------------------
|                             Routes
|---------------------------------------------------------------------
*/

import { AuthContext, AuthProvider } from "./contexts/AuthContext";
import MainLayout from "./layouts/MainLayout";
import NotFound from "./pages/redirects/NotFound";
import AuthLayout from "./layouts/AuthLayout";
import Signin from "./pages/auth/Signin";
import Locations from "./pages/locations/Locations";
import Verify from "./pages/auth/Verify";
import Distributions from "./pages/distributions/Distributions";

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="/signin" element={<Signin />} />
            <Route path="/verify" element={<Verify />} />
          </Route>
          <Route element={<MainLayout />}>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Distributions />
                </ProtectedRoute>
              }
            />
            <Route
              path="/bandwidth"
              element={
                <ProtectedRoute>
                  <Distributions />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </QueryClientProvider>
  );
}

function ProtectedRoute({ children }: { children: JSX.Element }) {
  let { token } = React.useContext(AuthContext);
  let location = useLocation();
  if (!token) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }
  return children;
}

export default App;

import {
    Button,
    Modal,
    Form,
    Row,
    Col,
    Input,
    message,
  } from "antd";
  import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleCreateDistribution } from "../../../api/distributions";
  
  export default function CreateDistribution({ isVisible, setVisible }: any) {
    const queryClient = useQueryClient();
    const [form] = Form.useForm();
  
  
    const { mutate, isPending } = useMutation({
      mutationFn: handleCreateDistribution,
      onSuccess: () => {
        message.success("Created Successfully");
        form.resetFields();
        queryClient.invalidateQueries({ queryKey: ["distributions"] });
        setVisible(false);
      },
      onError: () => {
        message.error("Something went wrong");
      },
    });
  
    const onSubmit = async () => {
  
      const values = await form.getFieldsValue();
      const data = {
        ...values,
      };
      await mutate(data);
    };
  
    const clearForm = async () => {
      form.resetFields();
    };
  
    return (
      <Modal
        open={isVisible}
        title="New Record"
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={clearForm}>
            Clear
          </Button>,
          <Button
            key="submit"
            form="createDistribution"
            htmlType="submit"
            type="primary"
            onSubmit={onSubmit}
            loading={isPending}
          >
            Create
          </Button>,
        ]}
      >
        <Form
          id="createDistribution"
          form={form}
          name="normal_login"
          className="login-form"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="name"
                label={"Name"}
                rules={[
                  {
                    required: true,
                    message: "Please enter name",
                  },
                ]}
              >
                <Input type="text" placeholder="Name" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="internetLink"
                label={"Internet Link (Mbps)"}
                rules={[
                  {
                    required: true,
                    message: "Please enter bandwidth",
                  },
                ]}
              >
                <Input type="text" placeholder="Internet Link (Mbps)" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="dataLink"
                label={"Data Link (Mbps)"}
                rules={[
                  {
                    required: true,
                    message: "Please enter bandwidth",
                  },
                ]}
              >
                <Input type="text" placeholder="Data Link (Mbps)" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
  
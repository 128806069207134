import { useQuery } from "@tanstack/react-query";
import { handleFetchDistributions } from "../../../api/distributions";
import { RiDeleteBin6Line, RiSearchLine } from "react-icons/ri";
import { useContext, useState } from "react";
import CreateDistribution from "./CreateDistribution";
import CreateSite from "./CreateSite";
import DeleteSite from "./DeleteSite";
import DeleteDistribution from "./DeleteDistribution";
import { LuPlus } from "react-icons/lu";
import { AuthContext } from "../../../contexts/AuthContext";
import { BiEdit } from "react-icons/bi";
import UpdateRemarks from "./UpdateRemarks";
import UpdateFeedback from "./UpdateFeedback";

export default function DistributionView() {
  const { user } = useContext(AuthContext);
  const [createDistribution, setCreateDistribution] = useState<boolean>(false);
  const [deleteDistribution, setDeleteDistribution] = useState<boolean>(false);
  const [createSite, setCreateSite] = useState<boolean>(false);
  const [updateRemarks, setUpdateRemarks] = useState<boolean>(false);
  const [updateFeedback, setUpdateFeedback] = useState<boolean>(false);
  const [deleteSite, setDeleteSite] = useState<boolean>(false);
  const [distribution, setDistribution] = useState<any>(null);
  const [editable, setEditable] = useState<boolean>(true);
  const [site, setSite] = useState<any>(null);

  const { data: distributions } = useQuery({
    queryKey: ["distributions"],
    queryFn: handleFetchDistributions,
  });

  return (
    <div className="flex flex-col text-xxs w-full px-2 sm:px-48">
      <div className="border border-black p-2 mt-8 mb-2 flex flex-col justify-center items-center">
        <div className="font-bold text-lg text-center">
          BANDWIDTH ALLOCATION ACROSS KJ GROUP FROM SIMBANET
        </div>
        <div className="flex items-center">
          {user?.isAdmin && editable && (
            <button
              onClick={() => setCreateDistribution(true)}
              className="border px-4 py-1 w-32 hover:bg-gray-100"
            >
              + Add Record
            </button>
          )}

          {user?.isAdmin && (
            <div className="mx-2">
              <div
                onClick={() => setEditable(!editable)}
                className=" flex items-center rounded-lg cursor-pointer"
              >
                <div className="flex">
                  <div className="flex justify-center items-center">
                    {editable ? (
                      <BiEdit color="#16a34a" size={24} />
                    ) : (
                      <BiEdit color="#9ca3af" size={24} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-12 sm:col-span-2 border-y border-l border-black">
          <div className="border-b  border-black text-white">-</div>
          <div className="flex">
            <div className="border py-1 font-bold text-center w-full">
              Site Name
            </div>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-4 border-y border-l border-black">
          <div className="border-b border-black text-center font-bold">
            Bandwidth Assigned
          </div>
          <div className="flex">
            <div className="border-r border-black px-4 text-center font-bold w-1/2">
              Internet Link (Mbps)
            </div>
            <div className="px-4 text-center  font-bold w-1/2">
              Data Link (Mbps)
            </div>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-4 border-y border-x border-black">
          <div className="border-b border-black text-white">-</div>
          <div className="flex">
            <div className="border py-1 font-bold text-center w-full">
              Internet Bandwidth Distribution - Users
            </div>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-2 border-y border-x border-black">
          <div className="border-b border-black text-white">-</div>
          <div className="flex">
            <div className="border py-1 font-bold text-center w-full">
              Remarks
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12">
        {distributions?.length == 0 && (
          <div className="col-span-12 border border-black p-2 mb-2 h-32 flex flex-col justify-center items-center">
            <div className="">
              <RiSearchLine size={28} color="#D2D2D2" />
            </div>
            <div className="text-gray-500 text-xxs">NO RECORDS FOUND</div>
          </div>
        )}
      </div>
      <div className="">
        {distributions?.map((distribution: any) => (
          <div className="grid grid-cols-12">
            <div className="col-span-12 sm:col-span-2 border-b border-l border-black">
              <div className=" py-1 px-2 text-gray-600 flex items-center">
                {user?.isAdmin && editable && (
                  <button
                    onClick={() => {
                      setDistribution(distribution);
                      setDeleteDistribution(true);
                    }}
                    className="hover:text-red-300 text-red-500 mr-2"
                  >
                    <RiDeleteBin6Line />
                  </button>
                )}
                {distribution?.name}
              </div>
            </div>
            <div className="col-span-12 sm:col-span-2 border-b border-l border-black">
              <div className="flex text-gray-600">
                <div className="px-4 text-center py-1 w-1/2">
                  {distribution?.internetLink}
                </div>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-2 border-b border-l border-black">
              <div className="flex text-gray-600">
                <div className="px-4 text-center py-1 w-1/2">
                  {distribution?.dataLink}
                </div>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-4 border-b border-x border-black">
              <div className="py-1 px-2">
                {distribution?.sites?.map((item: any) => (
                  <div className="flex">
                    {user?.isAdmin && editable && (
                      <button
                        onClick={() => {
                          setDistribution(distribution);
                          setSite(item);
                          setDeleteSite(true);
                        }}
                        className="hover:text-red-300 text-red-500 mr-2"
                      >
                        <RiDeleteBin6Line />
                      </button>
                    )}
                    <div className="">{item?.unit}</div>
                    <div className="mx-1">{item?.description}</div>
                  </div>
                ))}
              </div>
              {user?.isAdmin && (
                <button
                  className="flex items-center text-xxs p-1 mx-2 mb-2 hover:bg-gray-100 hover:"
                  onClick={() => {
                    setDistribution(distribution);
                    setCreateSite(true);
                  }}
                  style={{ fontSize: 10 }}
                >
                  <LuPlus />
                  <span className="mx-1">Add New</span>
                </button>
              )}
            </div>

            <div className="col-span-12 sm:col-span-2 border-b border-x border-black p-2">
              <div className="flex justify-between mt-1">
                <div className="">Remarks</div>
                <button
                  className="flex items-center text-xxs p-1 mb-2 hover:bg-gray-100 border"
                  onClick={() => {
                    setDistribution(distribution);
                    setUpdateRemarks(true);
                  }}
                  style={{ fontSize: 10 }}
                >
                  <LuPlus />
                  <span className="mx-1">
                    {distribution?.isCommented ? "Update" : "Add"}
                  </span>
                </button>
              </div>
              {distribution?.remarks ? (
                <button
                  onClick={() => {
                    setDistribution(distribution);
                    setUpdateRemarks(true);
                  }}
                  className=" hover:border-gray-100 group relative border w-full text-left"
                >
                  <div className="py-1 px-2 text-left">
                    {distribution?.remarks}
                  </div>
                  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-sm text-gray-500 opacity-0 group-hover:opacity-100 bg-white bg-opacity-90 pointer-events-none">
                    Update Remarks
                  </div>
                </button>
              ) : (
                <button
                  onClick={() => {
                    setDistribution(distribution);
                    setUpdateRemarks(true);
                  }}
                  className="m-2 px-2 hover:border-gray-100 group relative border w-full"
                >
                  <div className="py-1 text-gray-500 text-xxs">No Remarks</div>
                </button>
              )}

              {distribution?.isFeedbacked && (
                <div className="py-1">
                  {distribution?.isFullfield ? (
                    <div className="">Feedback: {distribution?.feedback}</div>
                  ) : (
                    <div className="flex justify-between">
                      <div className="text-red-400">Pending Feedback</div>

                      <button
                        onClick={() => {
                          setDistribution(distribution);
                          setUpdateFeedback(true);
                        }}
                        className="border py-1 px-2"
                      >
                        Respond
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <CreateDistribution
        isVisible={createDistribution}
        setVisible={setCreateDistribution}
      />
      <CreateSite
        isVisible={createSite}
        setVisible={setCreateSite}
        distribution={distribution}
      />
      <DeleteSite
        isVisible={deleteSite}
        setVisible={setDeleteSite}
        site={site}
        distribution={distribution}
      />
      <DeleteDistribution
        isVisible={deleteDistribution}
        setVisible={setDeleteDistribution}
        distribution={distribution}
      />
      <UpdateRemarks
        isVisible={updateRemarks}
        setVisible={setUpdateRemarks}
        distribution={distribution}
      />

      <UpdateFeedback
        isVisible={updateFeedback}
        setVisible={setUpdateFeedback}
        distribution={distribution}
      />
    </div>
  );
}

import { useContext, useState } from "react";
import CreateLocation from "./components/CreateDistribution";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { handleExpandLocation } from "../../api/locations";
import { message } from "antd";
import CreateSite from "./components/CreateSite";
import DeleteSite from "./components/DeleteSite";
import DeleteLocation from "./components/DeleteDistribution";
import { BiEdit } from "react-icons/bi";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router";
import DistributionView from "./components/DistributionView";
import { BsRouter } from "react-icons/bs";
import DistributionSmall from "./components/DistributionSmall";
import { FiMenu } from "react-icons/fi";
import { MdOutlineClose } from "react-icons/md";
import logo from "../../assets/images/white_logo.png";

export default function Distributions() {
  const { user } = useContext(AuthContext);
  const [createLocation, setCreateLocation] = useState<boolean>(false);
  const [deleteLocation, setDeleteLocation] = useState<boolean>(false);
  const [createSite, setCreateSite] = useState<boolean>(false);
  const [deleteSite, setDeleteSite] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>(null);
  const [location, setLocation] = useState<any>(null);
  const [editable, setEditable] = useState<boolean>(false);
  const [site, setSite] = useState<any>(null);
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => setIsOpen(!isOpen);

  const { mutate } = useMutation({
    mutationFn: handleExpandLocation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["distributions"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  return (
    <div className="flex">
      <div
        className={`fixed top-0 left-0 h-full bg-karimjee text-white w-56 transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0 transition-transform duration-300 z-20`}
      >
        <div className="min-h-screen bg-karimjee">
          <div className="flex items-center">
            <button
              onClick={() => {
                setSelected(null);
                navigate("/");
              }}
            >
              <img src={logo} alt="" width={150} className="m-2" />
            </button>
            {user?.isAdmin && (
              <div className="mx-4">
                <div
                  onClick={() => setEditable(!editable)}
                  className=" flex items-center rounded-lg cursor-pointer"
                >
                  <div className="flex px-1">
                    <div className="flex justify-center items-center">
                      {editable ? (
                        <BiEdit color="#16a34a" />
                      ) : (
                        <BiEdit color="#9ca3af" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="border-b p-2 mx-2 text-xxs text-white bg-karimjee-dark">
            <button
              onClick={() => {
                setLocation(null);
                setSite(null);
                navigate("/bandwidth");
              }}
              className="flex items-center"
            >
              <BsRouter /> <span className="mx-1">BANDWIDTH</span>
            </button>
          </div>
        </div>
      </div>

      <div className="flex-1 ml-0 lg:ml-56">
        <button
          className="lg:hidden fixed top-4 left-4 z-30 bg-gray-800 text-white p-2 rounded"
          onClick={toggleSidebar}
        >
          {isOpen ? <MdOutlineClose /> : <FiMenu />}
        </button>
        <div className="hidden lg:block flex-1 min-h-screen">
          <DistributionView />
        </div>
        <div className="lg:hidden">
          <DistributionSmall />
        </div>
      </div>

      <CreateLocation
        isVisible={createLocation}
        setVisible={setCreateLocation}
      />
      <CreateSite
        isVisible={createSite}
        setVisible={setCreateSite}
        location={location}
      />

      <DeleteSite
        isVisible={deleteSite}
        setVisible={setDeleteSite}
        site={site}
        location={location}
      />

      <DeleteLocation
        isVisible={deleteLocation}
        setVisible={setDeleteLocation}
        location={location}
      />
    </div>
  );
}

import { Outlet } from "react-router";

export default function MainLayout() {
  return (
    <div className="flex flex-col bg-white font-fish">
      <div className="flex-grow overflow-x-auto">
        <Outlet />
      </div>
    </div>
  );
}
